import React, { FC } from 'react'
import Line from './Line'

type Props = {
  dialog: Dialog
  problems: ProblemMap[0]
}

const Dialog: FC<Props> = props => {
  const { id, pages, choices } = props.dialog

  return (
    <div className="dialog">
      <div className="dialog__id">{id}</div>
      <div className="dialog__content">
        <div className="dialog__pages">
          {pages.map((page, i) => (
            <div key={i} className="dialog__page">
              {page.lines.map((line, j) => (
                <Line key={j} line={line} problems={props.problems?.[i]?.[j] ?? []} />
              ))}
            </div>
          ))}
        </div>
        <div className="dialog__choices">
          {choices.map((choice, i) => (
            <div key={i} className="dialog__choice">{choice}</div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dialog
