import React from 'react'
import Dialog from './Dialog'
import { findProblems } from './dialogAnalysis'

type Props = {
  dialogs: Dialog[]
}

const Dialogs = ({ dialogs }: Props) => {
  const problems = findProblems(dialogs)
  const problemLookup = problems.reduce<ProblemMap>((map, problem) => {
    const { dialogIdx, pageIdx, lineIdx } = problem
    return {
      ...map,
      [dialogIdx]: {
        ...map[dialogIdx],
        [pageIdx]: {
          ...map[dialogIdx]?.[pageIdx],
          [lineIdx]: (map[dialogIdx]?.[pageIdx]?.[lineIdx] ?? []).concat(problem)
        }
      }
    }
  }, {})

  return (
    <div id="dialogs">
      {dialogs.map((dialog, i) =>
        <Dialog key={i} dialog={dialog} problems={problemLookup[i] ?? []} />
      )}
    </div>
  )
}

export default Dialogs
