import React, { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useRef, useState, useTransition } from 'react'
import Dialogs from './Dialogs'
import { tokenizeDocument } from './hyperParser'

const hyperCrop = (text: string): Dialog[] => !text ? [] : text
  .replace(/^(?:[@/].*?|(?![^\n]))\n/gm, '') // remove annotations, comments and newlines
  .split(/\n(?=#.*?\n)/gm) // split at ids
  .map(dialog => {
    const [, id] = dialog.match(/^(#.+?)$/m) ?? [] // get id
    if (!id) {
      console.error('No id found in dialog:', dialog)
    }
    const [pages, ...choices] = dialog.slice((id?.length??0)+1).split(/\r?\n?\$\w+ /gm) // split at choices
    return {
      id,
      pages: pages
        .replace(/(?:[_|]|\[.*?\])/g, '') // remove underscores and inline tags
        .split(/\r?\n/g)
        .map(lines => ({
          lines: lines
            .replace(/\\n/g, '\n') // unescape newlines
            .replace(/([^\n]{1,38})( |\n|$)/g, (_,$1,$2) => $1 + ($2 ? '\n' : '')) // wrap lines after at most 38 characters and before explicit newlines
            .split('\n')
        })),
      choices
    }
  })

const renderLineChildren = (line: string, children: Token[]): React.ReactNode[] => {
  const reduced = children.reduce<[React.ReactNode[], number]>(([elements, lastIdx], { type, start, end }, j) =>
    [
      elements.concat([
        '█'.repeat(start - lastIdx),
        <span className={'token ' + type}>{'█'.repeat(end - start)}</span>
      ]),
      end
    ]
  , [[], 0])
  return reduced[0].concat('█'.repeat(line.length - reduced[1]))
}

const IOPane = () => {
  const [, startTransition] = useTransition()

  const [input, setInput] = useState('')
  const [tokens, setTokens] = useState<LineToken[]>([])
  const [output, setOutput] = useState([] as Dialog[])

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setInput(e.target.value)
  }

  const syntaxRef = useRef<HTMLDivElement>(null)

  const syncScroll = useCallback<MouseEventHandler<HTMLTextAreaElement>>(e => {
    if (!syntaxRef.current) return
    syntaxRef.current.scrollLeft = e.currentTarget.scrollLeft
    syntaxRef.current.scrollTop = e.currentTarget.scrollTop
  }, [])

  // debounce output update
  useEffect(() => {
    const timeout = setTimeout(() => {
      startTransition(() => {
        setTokens(tokenizeDocument(input));
        setOutput(hyperCrop(input))
      })
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [input])

  return (
    <div id="io-pane">
      <div id="input-pane" className="pane">
        <h1>Input</h1>
        <div id="input-area">
          <textarea className='input-textarea' value={input} onChange={handleChange} onScroll={syncScroll} />
          <div className="syntax-highlighting" ref={syntaxRef}>
            {tokens.map(({ type, line, children }, i) => <React.Fragment key={i}>
              <span className={'line-token ' + type}>
                {!children ? '█'.repeat(line.length) : renderLineChildren(line, children)}
              </span>
              <br/>
            </React.Fragment>)}
          </div>
        </div>
      </div>
      <div id="output-pane" className="pane">
        <h1>Output</h1>
        <div className="output">
          <Dialogs dialogs={output} />
        </div>
      </div>
    </div>
  )
}

export default IOPane
