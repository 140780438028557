
const tokenizeLine = (line: string): LineToken => {
  const lineStart = /^($|\$|@|#|\/\/|\[#)/.exec(line)?.[0]
  if (lineStart === '') {
    // empty line
    return { type: 'page', line, children: [] }
  }
  else if (lineStart === '//') {
    // comment
    return { type: 'comment', line }
  }
  else if (lineStart === '#') {
    // tag
    const tagName = /^#(\S+)/.exec(line)?.[1] ?? ''
    return { type: 'key', line, children: [
        { type: 'key_name', start: 1, end: tagName.length + 1 },
      ]
    }
  }
  else if (lineStart === '@') {
    // branch
    const [target, ...conditions] = line.slice(1).split(' ')
    const childTokens: Token[] = conditions.reduce<[Token[], number]>(([tokens, i], cond) => [
      tokens.concat({ type: 'branch_condition', start: i, end: i + cond.length }),
      i + cond.length + 1
    ], [[], target.length + 2])[0]

    return { type: 'branch', line, children: [
      { type: 'branch_target', start: 1, end: 1 + target.length },
      ...childTokens
    ] }
  }
  else if (lineStart === '[#') {
    // goto
    const [, target, clBr] = /^\[#(\S*)(\])$/.exec(line) ?? ['', '', '']
    return { type: 'goto', line, children: [
      { type: 'bracket', start: 0, end: 2 },
      { type: 'key_name', start: 2, end: 2 + target.length },
      { type: 'bracket', start: 2 + target.length, end: 2 + target.length + clBr.length },
    ] }
  }
  else if (lineStart === '$') {
    // choice
    const [ref] = line.split(' ')
    const target = ref.slice(1)
    const tokens: Token[] = []
    if (target) {
      tokens.push({ type: 'key_name', start: 1, end: 1 + target.length })
    }
    if (line.length > ref.length) {
      tokens.push({ type: 'string', start: ref.length + 1, end: line.length })
    }
    return { type: 'choice', line, children: tokens }
  }
  // dialog line
  const tokens: Token[] = []
  let rest = line
  let i = 0
  while (rest) {
    const [match, nextText, nextSpecial] = /^(.*?)(_+|\[.*?\]|\|.*?\||$)/.exec(rest) ?? ['', '', '']
    if (nextText) {
      tokens.push({ type: 'string', start: i, end: i + nextText.length })
      i += nextText.length
    }
    if (nextSpecial) {
      tokens.push({ type:
        nextSpecial[0] === '_' ? 'pause' :
        nextSpecial[0] === '[' ? 'event' :
        nextSpecial[0] === '|' ? 'bar' : 'unknown',
        start: i, end: i + nextSpecial.length })
      i += nextSpecial.length
    }
    rest = rest.slice(match.length)
  }

  return { type: 'page', line, children: tokens }
}

export const tokenizeDocument = (document: string): LineToken[] => {
  const documentLines = document.split('\n')

  const tokens: LineToken[] = []

  for (const line of documentLines) {
    const token = tokenizeLine(line)
    if (token) {
      tokens.push(token)
    }
  }

  return tokens
}
