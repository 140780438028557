import React from 'react'
import './App.scss'
import IOPane from './IOPane'

const App = () => {
  return (
    <div id="app">
      <IOPane />
    </div>
  )
}

export default App
