import React, { FC } from 'react'

type Props = {
  line: string
  problems: DialogProblem[]
}

const Line: FC<Props> = props => {
  const { line, problems } = props
  return (
    <div className="dialog__line">
      <div className="dialog__line__problems">
        {problems.map(({ range, problem }, i) => (
          <div key={i} className="dialog__line__problem"
            style={{ left: `${range[0]}ch`, width: `${range[1] - range[0]}ch` }}
          >
            <div className="dialog__line__problem__tooltip">
              {problem}
            </div>
          </div>
        ))}
      </div>
      <span className="dioalog__line__text">{line}</span>
    </div>
  )
}

export default Line
