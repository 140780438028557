import Dialog from "./Dialog"

export const findProblems = (dialogs: Dialog[]): DialogProblem[] => {
  const problems: DialogProblem[] = []

  for (const [dialogIdx, dialog] of dialogs.entries()) {
    for (const [pageIdx, page] of dialog.pages.entries()) {
      for (const [lineIdx, line] of page.lines.entries()) {
        // too many lines
        if (lineIdx > 4) {
          problems.push({ dialogIdx, pageIdx, lineIdx, range: [0, line.length], problem: "Too many lines" })
        }
        // overflowing line
        if (line.length > 38) {
          problems.push({ dialogIdx, pageIdx, lineIdx, range: [38, line.length], problem: "Line is too long" })
        }
        // double-space
        const dspMatch = / {2}/.exec(line)
        if (dspMatch) {
          problems.push({ dialogIdx, pageIdx, lineIdx, range: [dspMatch.index, dspMatch.index + 2], problem: "Double space" })
        }
        // boundary whitespace
        const bwMatch = /^\s|\s$/.exec(line)
        if (bwMatch) {
          problems.push({ dialogIdx, pageIdx, lineIdx, range: [bwMatch.index, bwMatch.index + 1], problem: "Boundary whitespace" })
        }
      }
    }
  }

  return problems
}
